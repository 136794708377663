export const firebaseConfig = {
    "projectId": "guidde-production",
    "appId": "1:484452355157:web:2c5a764b5d360dbef6d8dc",
    "databaseURL": "https://guidde-production-default-rtdb.firebaseio.com",
    "storageBucket": "guidde-production.appspot.com",
    "locationId": "us-central",
    "apiKey": "AIzaSyATarjLOqtsa2O2VJqtnv81Dje5REI2TYc",
    "authDomain": "app.guidde.com",
    "messagingSenderId": "484452355157",
    "environment": "production"
}

export const STIGG_CLIENT_KEY =
'$2b$10$vz2LfYEcVSchWKD8ZNlm1.zYHWPEdvjoWEHbKD3S9gWmT0yS80hou:0bd8f132-b309-46b2-b611-146a5c7a2d8f'
export const tapfiliateAccountId =
'38971-9bf427'